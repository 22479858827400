<template>
  <div>
    <div v-if="isAllowPage" class="pt-5 pb-3 w-50 m-auto">
      <security-banner></security-banner>
    </div>
    <div v-else class="autoPay-form">
      <page-body class="mx-lg">
        <div v-if="currentStep === 2">
          <b-button @click="handleBackClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3">Back</b-button>
        </div>
        <section class="section-1 bg-block mb-4">
          <h4>
            {{
              paymentInfo.isAutoPaySetup
                ? editedPayment.frequencyType !== 'Monthly'
                  ? 'Edit Yearly Payment Information'
                  : 'Edit Monthly Payment Information'
                : translations.tcNewBankAccountInformation
            }}
          </h4>
          <h1 class="sm mb-4">
            <span>{{ individualProfile.formal_name }}</span>
          </h1>
          <div class="body">
            <div v-if="currentStep === 1">
              <b-form>
                <b-form-group class="form-element">
                  <div class="g-row">
                    <div class="g-col-1 d-flex">
                      <b-form-group class="form-element">
                        <!-- Select Date -->
                        <template v-if="editedPayment.frequencyType === 'Monthly'">
                          <h3 class="flex-2 mr-3 text-left font-style-2">Select Date</h3>
                          <div class="mb-3">
                            <b-form-radio-group
                              id="rdbAuxPayment"
                              v-model="editedPayment.withdrawDate"
                              :options="optionsTransactionDate"
                              name="rdbAuxPayment"
                              disabled-field="notEnabled"
                              stacked
                            ></b-form-radio-group>
                          </div>
                        </template>
                        <!--Auto Pay Toggle Button For Annual Type Edit Form-->
                        <div
                          class="mb-3 mt-1 payment-switch d-flex flex-row"
                          v-if="editedPayment.frequencyType === 'Annually'"
                        >
                          <div @click="showAutoPayConfirmationModal">
                            <b-form-checkbox
                              :checked="!!editedPayment.isautopay"
                              switch
                              :disabled="true"
                            ></b-form-checkbox>
                          </div>
                          <div>
                            <span>Automated Payment</span>
                            <span v-if="!editedPayment.isautopay"> Disabled</span>
                            <span v-else> Enabled</span>
                          </div>
                        </div>
                        <!--Payment Type-->
                        <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcPaymentType }}</h3>
                        <b-form-select
                          v-model="editedPayment.paymenttype"
                          :options="optionsPaymentTypes"
                          class="form-control g-select flex-0 mr-3 mb-3"
                        >
                          <template slot="first"></template>
                        </b-form-select>

                        <!-- CREDIT CARD -->
                        <template v-if="editedPayment.paymenttype === 'CCD'">
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcCardNumber }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap': $v.editedPayment.cardnumber.$error && $v.editedPayment.cardnumber.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text6"
                              v-model="editedPayment.cardnumber"
                              @blur="$v.editedPayment.cardnumber.$touch"
                              class="flex-0 g-select"
                              type="text"
                              :placeholder="editedPayment.cardNumberDisplayField"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.cardnumber.$dirty">
                              <span v-if="!$v.editedPayment.cardnumber.required">{{ errorMsg.reqField }}</span>
                              <span v-if="!$v.editedPayment.cardnumber.numeric">Invalid card number</span>
                              <span v-if="!$v.editedPayment.cardnumber.minLength">At least 15 digits are required</span>
                            </span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcNameOnCard }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.nameonaccount.$error && $v.editedPayment.nameonaccount.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text7"
                              v-model="editedPayment.nameonaccount"
                              @blur="$v.editedPayment.nameonaccount.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span
                              v-if="$v.editedPayment.nameonaccount.$error && $v.editedPayment.nameonaccount.$dirty"
                              >{{ errorMsg.reqField }}</span
                            >
                          </div>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.expiremonth.$error && $v.editedPayment.expiremonth.$dirty
                            }"
                          >
                            <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcExpirationMonth }}</h3>
                            <b-form-select
                              v-model="editedPayment.expiremonth"
                              :options="optionsExpireMonth"
                              class="form-control g-select flex-0 mr-3"
                              @change="$v.editedPayment.expiremonth.$touch()"
                            >
                              <template slot="first"></template>
                            </b-form-select>
                            <span v-if="$v.editedPayment.expiremonth.$error && $v.editedPayment.expiremonth.$dirty">{{
                              !$v.editedPayment.expiremonth.isExpiryValid
                                ? 'Expiry month cannot be in the past'
                                : errorMsg.reqField
                            }}</span>
                          </div>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap': $v.editedPayment.expireyear.$error && $v.editedPayment.expireyear.$dirty
                            }"
                          >
                            <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcExpirationYear }}</h3>
                            <b-form-select
                              v-model="editedPayment.expireyear"
                              :options="optionsExpireYear"
                              class="form-control g-select flex-0 mr-3"
                              @change="$v.editedPayment.expireyear.$touch()"
                            >
                              <template slot="first"></template>
                            </b-form-select>
                            <span v-if="$v.editedPayment.expireyear.$error && $v.editedPayment.expireyear.$dirty">{{
                              !$v.editedPayment.expireyear.isExpiryValid
                                ? 'Expiry year cannot be in the past'
                                : errorMsg.reqField
                            }}</span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcCVC }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap': $v.editedPayment.cardcvc.$error && $v.editedPayment.cardcvc.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text8"
                              v-model="editedPayment.cardcvc"
                              @blur="$v.editedPayment.cardcvc.$touch"
                              class="flex-0 g-select inputCVC"
                              type="text"
                              maxlength="4"
                            ></b-form-input>
                            <div v-if="$v.editedPayment.cardcvc.$dirty">
                              <span v-if="!$v.editedPayment.cardcvc.required">{{ errorMsg.reqField }}</span>
                              <span v-if="!$v.editedPayment.cardcvc.numeric">Invalid CVC</span>
                            </div>
                            <a @click.prevent="showCVC = !showCVC" href="#">({{ translations.tcWhatIsCVC }})</a>
                          </div>

                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcAddress1 }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap': $v.editedPayment.address1.$error && $v.editedPayment.address1.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text9"
                              v-model="editedPayment.address1"
                              @blur="$v.editedPayment.address1.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.address1.$error && $v.editedPayment.address1.$dirty">{{
                              errorMsg.reqField
                            }}</span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcCity }}</h3>
                          <div
                            class="mb-3"
                            :class="{ 'error-boxWrap': $v.editedPayment.city.$error && $v.editedPayment.city.$dirty }"
                          >
                            <b-form-input
                              id="type-text10"
                              v-model="editedPayment.city"
                              @blur="$v.editedPayment.city.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.city.$error && $v.editedPayment.city.$dirty">{{
                              errorMsg.reqField
                            }}</span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcState }}</h3>
                          <div
                            class="mb-3"
                            :class="{ 'error-boxWrap': $v.editedPayment.state.$error && $v.editedPayment.state.$dirty }"
                          >
                            <b-form-select
                              v-model="editedPayment.state"
                              @blur="$v.editedPayment.state.$touch"
                              :options="optionsStates"
                              class="form-control g-select flex-0 mr-3"
                            >
                            </b-form-select>
                            <span v-if="$v.editedPayment.state.$error && $v.editedPayment.state.$dirty">{{
                              errorMsg.reqField
                            }}</span>
                          </div>
                          <template slot="first"></template>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcZip }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap': $v.editedPayment.zipcode.$error && $v.editedPayment.zipcode.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text11"
                              v-model="editedPayment.zipcode"
                              @blur="$v.editedPayment.zipcode.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.zipcode.$error && $v.editedPayment.zipcode.$dirty">
                              <span v-if="!$v.editedPayment.zipcode.required">{{ errorMsg.reqField }}</span>
                              <span v-if="!$v.editedPayment.zipcode.maxLength"
                                >No more than 10 digits are allowed.</span
                              >
                              <span v-if="!$v.editedPayment.zipcode.minLength">At least 5 digits are required.</span>
                              <span v-if="!$v.editedPayment.zipcode.pattern">
                                Invalid format, correct format is 12345 or 12345-6789.</span
                              >
                            </span>
                          </div>
                        </template>
                        <!-- /CREDIT CARD -->
                        <!-- ACH -->
                        <template v-if="editedPayment.paymenttype === 'ACH'">
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcNameOnAccount }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.nameonaccount.$error && $v.editedPayment.nameonaccount.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text12"
                              v-model="editedPayment.nameonaccount"
                              @blur="$v.editedPayment.nameonaccount.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span
                              v-if="$v.editedPayment.nameonaccount.$error && $v.editedPayment.nameonaccount.$dirty"
                              >{{ errorMsg.reqField }}</span
                            >
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcBankRoutingNumber }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.routingnumber.$error && $v.editedPayment.routingnumber.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text13"
                              v-model="editedPayment.routingnumber"
                              @blur="$v.editedPayment.routingnumber.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                              :placeholder="editedPayment.routingnumberDisplay"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.routingnumber.$dirty">
                              <span v-if="!$v.editedPayment.routingnumber.required">{{ errorMsg.reqField }}</span>
                            </span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">{{ translations.tcBankAccountNumber }}</h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.checkingnumber.$error && $v.editedPayment.checkingnumber.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text14"
                              v-model="editedPayment.checkingnumber"
                              @blur="$v.editedPayment.checkingnumber.$touch"
                              class="flex-0 g-select"
                              :placeholder="editedPayment.eftAccountNumberDisplayField"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.checkingnumber.$dirty">
                              <span v-if="!$v.editedPayment.checkingnumber.required">{{ errorMsg.reqField }}</span>
                            </span>
                          </div>
                          <h3 class="flex-2 mr-3 text-left font-style-2">
                            {{ translations.tcReEnterBankAccountNumber }}
                          </h3>
                          <div
                            class="mb-3"
                            :class="{
                              'error-boxWrap':
                                $v.editedPayment.checkingnumber2.$error && $v.editedPayment.checkingnumber2.$dirty
                            }"
                          >
                            <b-form-input
                              id="type-text15"
                              v-model="editedPayment.checkingnumber2"
                              @blur="$v.editedPayment.checkingnumber2.$touch"
                              class="flex-0 g-select"
                              type="text"
                              maxlength="75"
                            ></b-form-input>
                            <span v-if="$v.editedPayment.checkingnumber2.$dirty">
                              <span v-if="!$v.editedPayment.checkingnumber2.required">{{ errorMsg.reqField }}</span>
                              <span
                                v-if="
                                  !$v.editedPayment.checkingnumber2.sameAsCheckingnumber &&
                                    editedPayment.checkingnumber2
                                "
                                >Number does not match</span
                              >
                            </span>
                          </div>
                        </template>
                        <!-- /ACH -->
                      </b-form-group>
                    </div>
                    <div class="g-col-2" v-if="showCVC && editedPayment.paymenttype === 'CCD'">
                      <p>{{ translations.tcCVCDescription }}</p>
                      <p>{{ translations.tcCVCLocation }}</p>
                      <ul class="mb-1">
                        <li class="ml-1">{{ translations.tcCVCAmexLocation }}</li>
                        <li class="ml-1">{{ translations.tcCVCDiscoverMasterCardVisaLocation }}</li>
                      </ul>
                      <img src="@/assets/cvc_visa.gif" class="mb-1" :alt="translations.tcCVCLocationOnVisa" />
                      <img src="@/assets/cvc_amex.gif" class="mb-1" :alt="translations.tcCVCLocationOnAmex" />
                    </div>
                    <div class="container-fluid" v-if="editedPayment.paymenttype === 'ACH'">
                      <img
                        src="@/assets/checkRoutingNoText.gif"
                        class="check-image"
                        :alt="translations.tcRoutingNumberLocationOnCheck"
                      />
                      <div class="d-flex justify-content-around check-number-container">
                        <p>{{ translations.tcNumRouting }}</p>
                        <p>{{ translations.tcNumAccount }}</p>
                        <p>{{ translations.tcNumCheck }}</p>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </b-form>
            </div>
            <div v-else>
              <b-row>
                <b-col sm="12" class="detail-list">
                  <div class="detail-list__row">
                    <span class="strong_label">{{ translations.tcNameOnAccount }}:</span>
                    {{ editedPayment.nameonaccount }}
                  </div>

                  <template v-if="editedPayment.paymenttype === 'CCD'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcCardNumber }}:</span>
                      {{ displayCardNum }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcExpirationMonth }}:</span>
                      {{ editedPayment.expiremonth }}/{{ editedPayment.expireyear }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcNameOnCard }}:</span>
                      {{ editedPayment.nameonaccount }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcAddress1 }}:</span>
                      {{ editedPayment.address1 }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label"></span>
                      {{ editedPayment.city }}, {{ editedPayment.state }} {{ editedPayment.zipcode }}
                    </div>
                  </template>
                  <template v-if="editedPayment.paymenttype === 'ACH'">
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankRoutingNumber }}:</span>
                      {{ editedPayment.routingnumber }}
                    </div>
                    <div class="detail-list__row">
                      <span class="strong_label">{{ translations.tcBankAccountNumber }}:</span>
                      {{ editedPayment.checkingnumber }}
                    </div>
                  </template>
                  <div class="detail-list__row">
                    <span class="strong_label">Automated Payment Enabled:</span>
                    {{ editedPayment.isautopay ? 'Yes' : 'No' }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="body">
            <div>
              <b-button
                @click="handleNextCLick"
                v-if="currentStep === 1"
                variant="primary"
                class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                >Next</b-button
              >
              <b-button
                @click="handleSaveClick"
                v-if="currentStep === 2"
                variant="primary"
                class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcSave }}</b-button
              >
              <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
                translations.tcCancel
              }}</b-button>
            </div>
          </div>
        </section>
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import date_data from '@/json/date.json'
import pageBody from '@/components/page-components/PageBody.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { required, numeric, sameAs, minLength, maxLength } from 'vuelidate/lib/validators'
import SecurityBanner from '@/components/SecurityBanner'

export default {
  name: 'member-autopay-form',
  mixins: [translationMixin],
  data() {
    return {
      editedPayment: {
        cpi_key: '',
        cst_key: '',
        mbr_key: '',
        spouse_key: '',
        spouse_mbr_key: '',
        spouse_cpi_key: '',
        isautopay: false,
        paymenttype: 'CCD',
        frequencyType: null,
        withdrawDate: 0,
        nameonaccount: '',
        routingnumber: '',
        routingnumberDisplay: '',
        eftAccountNumberDisplayField: '',
        checkingnumber: '',
        checkingnumber2: '',
        cardNumberDisplayField: '',
        cardnumber: '',
        expiremonth: null,
        expireyear: null,
        cardcvc: '',
        address1: '',
        city: '',
        state: null,
        zipcode: '',
        email: ''
      },
      monthTranslated: 'Month',
      optionsPaymentTypes: [
        { value: null, text: 'Payment Type', disabled: true },
        { value: 'ACH', text: 'ACH' },
        { value: 'CCD', text: 'Credit Card' }
      ],
      PaymentMethod_ACH_GuidComparison: [
        '3cde1523-989d-4264-bf2b-1f04e3752498',
        '9dcdbfc1-c63f-49a1-b3a9-784616c31b29'
      ],
      optionsTransactionDate: [
        { text: '1st of the Month', value: 1, notEnabled: true },
        { text: '15th of the Month', value: 15, notEnabled: true }
      ],
      errorMsg: {
        reqField: "Required field. Can't be left empty"
      },
      showCVC: false,
      stateTranslated: 'State',
      translations: {},
      yearTranslated: 'Year',
      currentStep: 1
    }
  },
  validations: {
    editedPayment: {
      cardnumber: { required, numeric, minLength: minLength(15) },
      nameonaccount: { required },
      cardcvc: { required, numeric },
      expiremonth: {
        required,
        isExpiryValid(value, vm) {
          if (!value || !vm.expireyear) {
            return true
          }
          const currentYear = new Date().getFullYear()
          const currentMonth = new Date().getMonth() + 1 // getMonth() returns 0-11 for Jan-Dec
          const expiryYear = parseInt(vm.expireyear, 10)
          const expiryMonth = parseInt(value, 10)
          return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)
        }
      },
      expireyear: {
        required,
        isExpiryValid(value, vm) {
          if (!value || !vm.expiremonth) {
            return true
          }
          const currentYear = new Date().getFullYear()
          const currentMonth = new Date().getMonth() + 1
          const expiryYear = parseInt(value, 10)
          const expiryMonth = parseInt(vm.expiremonth, 10)
          return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)
        }
      },
      address1: { required },
      city: { required },
      state: { required },
      zipcode: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(10),
        pattern(value) {
          return /^\d{5}(-\d+)?$/.test(value)
        }
      },
      checkingnumber: { required },
      checkingnumber2: { required, sameAsCheckingnumber: sameAs('checkingnumber') },
      routingnumber: { required }
    }
  },
  methods: {
    ...mapActions({
      loadUsStates: 'membership/loadUsStates',
      saveAutopay: 'membership/saveAutopay',
      setLoadingStatus: 'menu/setLoadingStatus',
      setIsIndividualProfileLifeTime: 'membership/setIsIndividualProfileLifeTime'
    }),

    formatDollars(value) {
      return Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    },
    showAutoPayConfirmationModal() {
      const isAutoPay = this.editedPayment.isautopay
      this.$swal({
        html: `
        <div class="confirm-modal-inner">
          <img src="${require('../../../../assets/svgs/warning.svg')}" />
          <h3>Are you sure?</h3>
          <p>Do you want to turn ${isAutoPay ? 'off' : 'on'} the automatic renewal for your payment method?</p>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'swalCustomButton-confirm',
        cancelButtonClass: 'swalCustomButton-cancel'
      }).then(result => {
        if (result.isConfirmed) {
          this.editedPayment.isautopay = !isAutoPay
        }
      })
    },
    //To Check if zipCode is valid or not
    isValidZipCode(zipCode) {
      return /^\d{5}(-\d+)?$/.test(zipCode)
    },

    //To Check if year and month are valid
    isExpiryValid(expiryYear, expiryMonth) {
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1 // January is 0

      expiryYear = parseInt(expiryYear, 10)
      expiryMonth = parseInt(expiryMonth, 10)

      return expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)
    },

    handleValidation () {
      const PAYMENT_FIELDS_ALERT_TYPE = {
        REQUIRED_FIELDS: this.translations.tcErrorAllFieldsRequired,
        CHECKING_NUMBER_ACH: this.translations.tcErrorCheckingNumsDoNotMatch,
        PAYMENT_TYPE_REQUIRED: this.translations.tcErrorPaymentTypeRequired
      }

      const showEditPaymentAlert = (ALERT_TYPE) => {
        PAYMENT_FIELDS_ALERT_TYPE[ALERT_TYPE] &&
          this.$swal({
            icon: 'error',
            text: PAYMENT_FIELDS_ALERT_TYPE[ALERT_TYPE],
            confirmButtonText: this.translations.tcOk || 'Ok'
          })
      }


      if (!this.editedPayment.paymenttype) {
        showEditPaymentAlert('PAYMENT_TYPE_REQUIRED')
      }

      if (this.editedPayment.paymenttype === 'CCD') {
        if (
          !this.$v.editedPayment.cardnumber.required ||
          !this.$v.editedPayment.nameonaccount.required ||
          !this.$v.editedPayment.expiremonth.required ||
          !this.$v.editedPayment.expireyear.required ||
          !this.$v.editedPayment.cardcvc.required ||
          !this.$v.editedPayment.address1.required ||
          !this.$v.editedPayment.city.required ||
          !this.$v.editedPayment.state.required ||
          !this.$v.editedPayment.zipcode.required
        ) {
          showEditPaymentAlert('REQUIRED_FIELDS')
          return
        }
      } else {
        if (
          !this.$v.editedPayment.nameonaccount.required ||
          !this.$v.editedPayment.routingnumber.required ||
          !this.$v.editedPayment.checkingnumber.required ||
          !this.$v.editedPayment.checkingnumber2.required
        ) {
          showEditPaymentAlert('REQUIRED_FIELDS')
          return
        } else if (this.editedPayment.checkingnumber !== this.editedPayment.checkingnumber2) {
          showEditPaymentAlert('CHECKING_NUMBER_ACH')
          return
        }
      }
      if (!this.isAutoPayFormInvalid) {
        this.currentStep++
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      }
    },

    async handleSaveClick() {
      var payload = {
        cpi_key: this.editedPayment.cpi_key,
        cst_key: this.editedPayment.cst_key,
        mbr_key: this.editedPayment.mbr_key,
        spouse_key: this.editedPayment.spouse_key,
        spouse_mbr_key: this.editedPayment.spouse_mbr_key,
        isautopay: this.editedPayment.isautopay ? 1 : 0,
        paymenttype: this.editedPayment.paymenttype,
        nameonaccount: this.editedPayment.nameonaccount,
        routingnumber: this.editedPayment.routingnumber,
        checkingnumber: this.editedPayment.checkingnumber,
        checkingnumber2: this.editedPayment.checkingnumber2,
        cardnumber: this.editedPayment.cardnumber,
        expiremonth: this.editedPayment.expiremonth,
        expireyear: this.editedPayment.expireyear,
        cardcvc: this.editedPayment.cardcvc,
        address1: this.editedPayment.address1,
        city: this.editedPayment.city,
        state: this.editedPayment.state,
        zipcode: this.editedPayment.zipcode,
        email: this.editedPayment.email,
        frequency: this.editedPayment.frequencyType,
        withdrawDate: this.editedPayment.withdrawDate
      }

      let response = false
      await Promise.all([this.setLoadingStatus(true), (response = await this.saveAutopay(payload))]).then(() => {
        this.setLoadingStatus(false)
      })
      if (response === false) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorDuringSave,
          confirmButtonText: this.translations.tcOk || 'Ok'
        }).then(result => {
          return
        })
      } else if (response.data.response && response.data.response === '0') {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorUpdatingAutopay,
          confirmButtonText: this.translations.tcOk || 'Ok'
        })
          .then(result => {
            console.error('ERROR: ', response.data.message)
          })
          .then(() => {
            return
          })
      } else {
        this.$swal({
          icon: 'success',
          text: this.translations.tcAutopaySaved,
          confirmButtonText: this.translations.tcOk || 'Ok'
        })
          .then(result => {
            this.handleCancelClick()
          })
          .then(() => {
            return
          })
      }
    },

    handleCancelClick() {
      this.$router.go(-1)
    },

    handleNextCLick() {
      this.$v.editedPayment.$touch()
      this.handleValidation()
    },

    handleBackClick() {
      this.currentStep = 1
    },
    updateDynamicText() {
      if (!this.translations) return false

      this.optionsPaymentTypes[0].text = this.translations.tcPaymentType
      this.optionsPaymentTypes[1].text = this.translations.tcACH
      this.optionsPaymentTypes[2].text = this.translations.tcCreditCard
      this.monthTranslated = this.translations.tcMonth
      this.yearTranslated = this.translations.tcYear
      this.stateTranslated = this.translations.tcState
      return true
    }
  },
  computed: {
    ...mapGetters({
      convertedUsStates: 'membership/convertedUsStates',
      individualProfile: 'membership/individualProfile',
      paymentInfo: 'membership/paymentInfo',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      usStates: 'membership/usStates',
      isIndividualProfileMemberLifetime: 'membership/isIndividualProfileMemberLifetime'
    }),

    isAutoPayFormInvalid () {
      const isCreditCardInvalid = this.$v.editedPayment.cardnumber.$invalid ||
        this.$v.editedPayment.nameonaccount.$invalid ||
        this.$v.editedPayment.expiremonth.$invalid ||
        this.$v.editedPayment.expireyear.$invalid ||
        this.$v.editedPayment.cardcvc.$invalid ||
        this.$v.editedPayment.address1.$invalid ||
        this.$v.editedPayment.city.$invalid ||
        this.$v.editedPayment.state.$invalid ||
        this.$v.editedPayment.zipcode.$invalid ||
        this.$v.editedPayment.expireyear.$invalid ||
        this.$v.editedPayment.expiremonth.$invalid ||
        this.$v.editedPayment.zipcode.$invalid
      const isACHInvalid = this.$v.editedPayment.nameonaccount.$invalid || this.$v.editedPayment.routingnumber.$invalid || this.$v.editedPayment.checkingnumber.$invalid ||
        this.$v.editedPayment.checkingnumber2.$invalid
      const isPaymentTypeCard = this.editedPayment.paymenttype === 'CCD'
      return isPaymentTypeCard ? isCreditCardInvalid : isACHInvalid

    },
    isAllowPage() {
      return this.isIndividualProfileMemberLifetime
    },
    displayCardNum() {
      return this.editedPayment.cardnumber.length > 0
        ? 'xxxxxxxxxxxx' +
            this.editedPayment.cardnumber.substring(
              this.editedPayment.cardnumber.length - 4,
              this.editedPayment.cardnumber.length
            )
        : ''
    },

    // FormOptions
    optionsExpireYear() {
      const currentYear = new Date().getFullYear()
      const startYear = currentYear
      let years = Array.from(new Array(14), (val, index) => {
        return { value: startYear + index, text: startYear + index }
      })
      return [{ value: null, text: this.yearTranslated, disabled: true }, ...years]
    },
    optionsExpireMonth() {
      const objDate = new Date() // create temp. Date object, which will be set in each loop for the translation
      let months = date_data.months.map(x => {
        objDate.setMonth(x.value - 1, 1) // set Date object to the month from the loop
        const text = objDate.toLocaleString(this.prefCulture, { month: 'long' })
        return { value: x.value.length === 1 ? '0' + x.value : x.value, text }
      })
      return [{ value: null, text: this.monthTranslated, disabled: true }, ...months]
    },
    optionsStates() {
      return [{ value: null, text: this.stateTranslated, disabled: true }, ...this.convertedUsStates]
    },
    // END FormOptions
    cardExpireYearMonth() {
      if (!this.paymentInfo.Membership[0].cardExpirationField) {
        return ['', '']
      }
      const YearMonth = this.paymentInfo.Membership[0].cardExpirationField.split('/')
      return YearMonth
    }
  },
  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey) {
      this.$router.push({ name: 'membership' })
    }

    try {
      this.setLoadingStatus(true)
      await Promise.all([this.loadUsStates(), this.getViewTranslations(), this.setIsIndividualProfileLifeTime()]).then(
        () => {
          this.updateDynamicText()
          if (this.paymentInfo.Membership.length !== 0) {
            // Only for editing an existing autopay
            let membership = this.paymentInfo.Membership[0]
            this.editedPayment.cpi_key = !!membership.cpiKeyField ? membership.cpiKeyField : ''
            this.editedPayment.cst_key = !!membership.cpiCstKeyField ? membership.cpiCstKeyField : ''
            this.editedPayment.mbr_key = !!membership.membershipKeyField ? membership.membershipKeyField : ''
            this.editedPayment.spouse_key = !!membership.spouseCstKeyField ? membership.spouseCstKeyField : ''
            this.editedPayment.spouse_mbr_key = !!membership.spouseMbrKeyField ? membership.spouseMbrKeyField : ''
            this.editedPayment.spouse_cpi_key = !!membership.spouseCpiKeyField ? membership.spouseCpiKeyField : ''
            this.editedPayment.isautopay = !!this.paymentInfo.isAutoPaySetup ? this.paymentInfo.isAutoPaySetup : false
            this.editedPayment.paymenttype = this.PaymentMethod_ACH_GuidComparison.includes(
              membership.paymentMethodField
            )
              ? 'ACH'
              : 'CCD'
            this.editedPayment.nameonaccount = !!membership.creditCardHoldersNameField
              ? membership.creditCardHoldersNameField
              : ''
            this.editedPayment.routingnumber = ''
            this.editedPayment.routingnumberDisplay =  ''
            this.editedPayment.eftAccountNumberDisplayField = ''
            this.editedPayment.checkingnumber = ''
            this.editedPayment.checkingnumber2 = ''
            this.editedPayment.cardnumber = ''
            this.editedPayment.cardNumberDisplayField = ''
            this.editedPayment.expiremonth = !!this.cardExpireYearMonth[1] ? this.cardExpireYearMonth[1] : null
            this.editedPayment.expireyear = !!this.cardExpireYearMonth[0] ? this.cardExpireYearMonth[0] : null
            this.editedPayment.cardcvc = ''
            this.editedPayment.address1 = !!membership.streetField ? membership.streetField : ''
            this.editedPayment.city = !!membership.cityField ? membership.cityField : ''
            this.editedPayment.state = !!membership.stateField ? membership.stateField : null
            this.editedPayment.zipcode = !!membership.zipField ? membership.zipField : ''
            this.editedPayment.email = !!membership.email ? membership.email : ''
          } else {
            this.editedPayment.cst_key = this.individualProfile.ind_key
            this.editedPayment.spouse_key = this.individualProfile.spouse_ind_key
            this.editedPayment.isautopay = false
            this.editedPayment.paymenttype = 'CCD'
          }
          this.editedPayment.frequencyType = this.paymentInfo.Frequency
          this.editedPayment.withdrawDate = this.paymentInfo.WithdrawDate
        }
      )
    } catch (e) {
      console.error('Error in MemberAutopayForm.vue, created(), ', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    pageBody: pageBody,
    SecurityBanner
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.inputCVC {
  width: 100px;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;
  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.check-image {
  width: 100%;
}

.check-number-container {
  max-width: 600px;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;
  @include breakpoint(sm) {
    width: 24px;
  }
  input {
    order: 2;
  }
  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;
    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;
  @include breakpoint(sm) {
    flex-wrap: wrap;
  }
  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;
    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }
  .g-col-2 {
    max-width: 672px;
    width: 100%;
    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;
    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;
  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-select:disabled {
  color: #565656 !important;
  background-color: #d4d4d4;
  cursor: not-allowed;
}
input[type='radio']:disabled + label {
  cursor: not-allowed;
}
.payment-switch {
  line-height: 27px;
}
.payment-switch .custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 3rem;
  pointer-events: all;
  border-radius: 1rem;
  height: 1.4rem;
}
.payment-switch .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2.5px);
  left: calc(-2.25rem + 2px);
  width: calc(1.3rem - 4px);
  height: calc(1.3rem - 4px);
}
.payment-switch .custom-control-input:checked ~ .custom-control-label::before,
.payment-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: rgba(21, 101, 192, 0.5);
  border-color: rgba(21, 101, 192, 0.5);
}
.payment-switch .custom-control-input[disabled] ~ .custom-control-label::before,
.payment-switch .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(194, 194, 194, 1);
}
.payment-switch .custom-switch .custom-control-label::after {
  background-color: #f1f1f1;
}
.payment-switch .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background: #1565c0;
  transform: translateX(1.6rem);
}
.payment-switch .custom-switch .custom-control-label {
  padding-left: 18px;
  padding-top: 3px;
}
.detail-list {
  margin-bottom: 30px;
}
.detail-list .strong_label {
  font-weight: bold;
  min-width: 200px;
  display: inline-flex;
  color: #262626;
  flex-wrap: wrap;
  max-width: 200px;
  line-height: 20px;
}
.detail-list__row {
  margin-bottom: 6px;
  color: #262626;
}
.confirm-modal-inner img {
  margin: 15px auto 25px;
}
.confirm-modal-inner h3 {
  text-transform: uppercase;
  margin-bottom: 18px;
}
.swalCustomButton-confirm {
  background: #003946 !important;
  text-transform: uppercase;
  border: 2px solid #003946 !important;
}
.swalCustomButton-cancel {
  text-transform: uppercase;
  background: #fff !important;
  border: 2px solid #003946 !important;
  color: #003946 !important;
}
.autoPay-form .error-boxWrap input,
.error-boxWrap select,
.error-boxWrap .g-select:not(.is-invalid) {
  border: 1px solid red !important;
  border-color: 1px solid red !important;
  box-shadow: 0 0 5px red;
}

.autoPay-form .error-boxWrap span {
  color: red;
}
</style>
